import React, { useState, useEffect } from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { css, jsx } from '@emotion/core';
import { navigate } from 'gatsby';

import { RouteComponentProps } from '@reach/router';
import {
  parseLocationSearch,
  mapSearchToTracking,
} from '../components/hooks/useTracking';

import LeadFormRibbon1Step, {
  LeadFormValues,
} from '../components/LeadFormRibbon1Step';
import {
  GENERAL_ZIPLINE_KEY,
  PRIVATE_LOANS_ZIPLINE_KEY,
  GENERAL_PRIVATE_THANK_YOU,
  ZIPLINE_BASE_URL,
  GENERAL_THANK_YOU_PAGE,
} from '../utils/constants';
import docupop_logo from '../images/docupop_logo.png';

import initTrackAbandonment from '../utils/trackFormAbandonment';

const bugsnagClient = bugsnag('280b2412a99d2fa229ca2a8eb91dbecf');
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

const Page = (props: RouteComponentProps) => {
  const [error, setError] = useState('');
  const [prepopValues, setPrepopValues] = useState();

  const searchObj = parseLocationSearch(props.location!.search);
  const trackingData = mapSearchToTracking(searchObj);

  useEffect(() => {
    initTrackAbandonment();
  }, []);

  const submitLead = (values: LeadFormValues) => {
    let ziplineKey = GENERAL_ZIPLINE_KEY;
    let thanksUrl = GENERAL_THANK_YOU_PAGE;
    if (values.loans_type && values.loans_type.includes('Private')) {
      ziplineKey = PRIVATE_LOANS_ZIPLINE_KEY;
      thanksUrl = GENERAL_PRIVATE_THANK_YOU;
    }

    return fetch(`${ZIPLINE_BASE_URL}${ziplineKey}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...values,
        ...trackingData,
        ...{ url: window.location.href },
      }),
    })
      .then(response => {
        navigate(thanksUrl);
        return response.json();
      })
      .catch(error => {
        console.log('submitLead error', error);
        bugsnagClient.notify(error);
        setError('There was an error submitting your data');
      });
  };

  return (
    <ErrorBoundary>
      <div className="bg-blue-700 h-2" />
      <div
        className="bg-blue-300 pt-6 font-sans"
        css={css`
          background: #7abcff; /* Old browsers */
          background: -moz-radial-gradient(
            center,
            ellipse cover,
            #7abcff 0%,
            #60abf8 44%,
            #4096ee 100%
          ); /* FF3.6-15 */
          background: -webkit-radial-gradient(
            center,
            ellipse cover,
            #7abcff 0%,
            #60abf8 44%,
            #4096ee 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: radial-gradient(
            ellipse at center,
            #7abcff 0%,
            #60abf8 44%,
            #4096ee 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7abcff', endColorstr='#4096ee',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        `}
      >
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <div className="flex mb-4">
              <div className="w-1/2">
                <img
                  src={docupop_logo}
                  style={{ width: '200px', margin: 'auto' }}
                  alt="docupop logo"
                />
              </div>
              <div className="w-1/2 text-right text-white font-sans">
                <p>Need Help Now? Call Us!</p>
                <p className="-mt-5">
                  <a
                    href="tel:+18888385871"
                    className="text-gray-800 text-xl font-bold no-underline"
                  >
                    (888) 838-5871
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mx-auto text-center">
              <h1 className="text-gray-800">
                Refinance or Consolidate Your Student Loans
              </h1>
              <div className="mb-4 text-blue-900">
                You have more options to lower your monthly payments than you
                realize!
              </div>
            </div>
            <div className="flex mb-4">
              <LeadFormRibbon1Step
                initialValues={prepopValues ? prepopValues : {}}
                onSubmit={(values: LeadFormValues) => {
                  return submitLead(values);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-12 px-4 font-sans">
        <h4 className="uppercase mb-10">How it works</h4>
        <div className="px-2">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full lg:w-1/3 px-2 mb-6 flex flex-wrap">
              <div className="font-bold text-xl text-white bg-blue-700 rounded-full h-12 w-12 flex items-center justify-center">
                1
              </div>
              <div className="text-right ml-4 mt-3">
                <h4 className="text-blue-700 text-lg">
                  Tell us about your loans
                </h4>
              </div>
              <div className="w-full mt-2 px-4">
                An overview of your student loan portfolio is all it takes for
                us to start working on your behalf. Our advisors are experts who
                will walk you through the programs.
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-2 mb-6 flex flex-wrap">
              <div className="font-bold text-xl text-white bg-blue-700 rounded-full h-12 w-12 flex items-center justify-center">
                2
              </div>
              <div className="text-right ml-4 mt-3">
                <h4 className="text-blue-700 text-lg">
                  We tell you your options
                </h4>
              </div>
              <div className="w-full mt-2 px-4">
                Lower payments are possible. There are extended payment programs
                and plans that are based on your current income. If you qualify,
                your loans can also be forgiven.
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-2 mb-6 flex flex-wrap">
              <div className="font-bold text-xl text-white bg-blue-700 rounded-full h-12 w-12 flex items-center justify-center">
                3
              </div>
              <div className="text-right ml-4 mt-3">
                <h4 className="text-blue-700 text-lg">
                  You get lower payments
                </h4>
              </div>
              <div className="w-full mt-2 px-4">
                The variety of programs means your student loan payments will no
                longer be a burden. We'll handle all the work to get your
                consolidation or forgiveness finalized.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-sans text-sm text-gray-600 bg-gray-800 mt-64">
        <div className="container mx-auto py-20">
          <p>
            Docupop is a private company and is NOT AFFILIATED WITH AND DOES NOT
            HAVE ANY SPECIAL RELATIONSHIP WITH THE DEPARTMENT OF EDUCATION, loan
            servicers or any other academic or governmental entity. You can
            apply for loan consolidation or other repayment options through the
            Department of Education (DOE) at no cost, but you are instead
            choosing to use Docupop services to assist you prepare and process
            the consolidation and repayment option application paperwork for
            programs offered by the DOE. Docupop does not and will not make any
            payments for or on behalf of Student.
          </p>
          <p>
            &copy;{`${new Date().getFullYear()}`} Docupop. All rights reserved.
          </p>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Page;
